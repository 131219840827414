<template>
    <div>
        <portal to="layout-default-header">
            <header-edit
                :title="user.isSame($me) ? $t('users.edit.title') : user.name"
                portal-target-name="profile-edit-actions"
            />
        </portal>

        <side-nav-router-view
            :nav-items="navItems"
            :user="user"
        />
    </div>
</template>

<script>
import Role from '@/library/enumerations/Role';
import HeaderEdit from '@/components/layouts/HeaderEdit';
import {User} from '@/models/User';
import SideNavRouterView from '@/components/layouts/SideNavRouterView';

export default {
    name: 'UserEditRouterView',
    components: {HeaderEdit, SideNavRouterView},
    props: {
        user: {
            type: User,
            required: true,
        },
    },
    computed: {
        navItems() {
            let items = [{location: this.user.getLocation('edit')}];

            if (
                this.user.id === this.$me.id
                && this.user.hasRoles(Role.COACH)
                && !!this.user.company
            ) {
                items.unshift({location: this.user.getLocation('edit.company')});
            }

            return items.length === 1 ? [] : items;
        },
    },
};
</script>
